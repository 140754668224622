import React, { useState, useEffect } from 'react'
import Header from '../Shared/Header'
import '../assets/css/custom.css'
import Footer from '../Shared/Footer'
import SideNav from '../Shared/SideNav'
import Loader from '../Components/Loader'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom'
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { Device } from '@capacitor/device'

function Dashboard() {
	const [open, setOpen] = useState(false)
	const [account, setAccount] = useState(false)
	const [side, setSide] = useState('')
	const [loader, setLoader] = useState(true)
	// const [appUpdate, setAppUpdate] = useState(false)
	const [show, setShow] = useState(false)
	const handleClick = () => {
		setOpen(!open)
		setSide('left')
	}
	const handleAccount = () => {
		setAccount(!account)
		setSide('right')
	}

	const performImmediateUpdate = async () => {
		const result = await AppUpdate.getAppUpdateInfo();
		if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
		  	return;
		}
		if (result.immediateUpdateAllowed) {
			setShow(false)
		  	const result = await AppUpdate.performImmediateUpdate()
			// console.log('result', JSON.stringify(result))
			if(result.code === 1){
				performImmediateUpdate()
			}
			return;
		}
	};

	const onPopupClose = () => {
		setShow(false)
		localStorage.setItem('initialLogin', false)
	}

	const getDeviceId = async () => {
		const device = await Device.getInfo();
		if(device.platform !== 'web') performImmediateUpdate()
	}

	useEffect(() => {
		var t = localStorage.getItem('initialLogin')
		var isFree = localStorage.getItem('isFree')
		// console.log('initial login', t)
		setTimeout(() => {
			setLoader(false)
		}, 2000)
		setTimeout(() => {
			setShow((t === 'true' && isFree === 'free') ? true : false)
			// setShow(isFree === 'free' ? true : false)
		}, 2500)
		getDeviceId();
	}, [])

	const toPlaystore = () => {
		var ua = navigator.userAgent.toLowerCase();
		var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
		console.log('console', ua)
		console.log('is android', isAndroid)
		if(isAndroid) {
			// Do something!
			// Redirect to Android-site?
			window.location.href = 'https://play.google.com/store/apps/details?id=com.goldtreeastro.app';
		}
	}

	return (

		<div className="page page--intro" data-page="intro">
			<Header onOpen={handleClick} onOpenAcc={handleAccount} />
			<SideNav onPass={open} onOpen={handleClick} move={side} onRight={account} onOpenAcc={handleAccount} />
			<div className="swiper-container slider-intro slider-intro--bottom-nav">
				<div className="swiper-wrapper">

					<div className="swiper-slide slider-intro__slide" >
						<div className="slider-intro__caption caption">
							<div className="caption__content">
								<h2 className="caption__title" >Welcome to Astro Light!</h2>
								<p className="caption__text" >Great Life is calling! <br />Just Pay Rs 528/- for 15 minutes only<br /> (@ India only) and know your specific future by highly trained Vedic Astrologer's (Trained by Pt. Dinesh Guruji) with free Remedy for your better and bright future!</p>
								{/* <Link to={'/agents'} className="caption__more button button--small button--blue" >Get Started</Link> */}

								<p className="caption__text" style={{ marginTop: 20 }} >For further details contact</p>
								<i className="fa fa-whatsapp" style={{ fontSize: 18, marginRight: 10 }}></i><a href="https://wa.me/+919844416555">+91-9844416555</a><br />

								<p className="caption__text" style={{ marginTop: 40 }} >For Consultation Download</p>
								<div onClick={toPlaystore}>
									<img src={require("../assets/images/google_play_available.png")} alt='' style={{ width: '50%', maxWidth:'100%'}}/>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className="slider-intro__skip"><div className="text-animation">Gold Tree Astro</div></div>
				<div className="swiper-pagination slider-intro__pagination"></div>
				<div className="swiper-button-prev slider-intro__prev d-none"></div>
				<div className="swiper-button-next slider-intro__next d-none"></div>
			</div>
			{/* <Footer move={side} onRight={account} onOpenAcc={handleAccount} /> */}

			<Loader fade={loader ? 'fadein' : 'fadeout'} />

			{/* <Popup open={show} closeOnDocumentClick onClose={onPopupClose}>
				<div className="modal " >
					<Link className="close" onClick={onPopupClose}>
						<img src={require("../assets/images/icons/black/close.svg").default} style={{ width: 10 }} alt='' />
					</Link>
                        <img src={require("../assets/images/banner.png")} alt='' style={{maxWidth:'100%'}}/>
				</div>
			</Popup> */}

		</div>

	)
}

export default Dashboard
import React, { useState, useEffect } from 'react'
import Header from '../Shared/Header'
import '../assets/css/custom.css'
import Footer from '../Shared/Footer'
import SideNav from '../Shared/SideNav'
import Loader from '../Components/Loader'
import 'reactjs-popup/dist/index.css';

function Privacy() {
    const [open, setOpen] = useState(false)
    const [account, setAccount] = useState(false)
    const [side, setSide] = useState('')
    const [loader, setLoader] = useState(true)
    
    const handleClick = () => {
        setOpen(!open)
        setSide('left')
    }
    const handleAccount = () => {
        setAccount(!account)
        setSide('right')
    }

    useEffect(() => {
        setTimeout(() => {
			setLoader(false)
		}, 1000)
    }, [])


    return (
        <div className="page page--intro" data-page="intro">
            <Header onOpen={handleClick} onOpenAcc={handleAccount} />
            <SideNav onPass={open} onOpen={handleClick} move={side} onRight={account} onOpenAcc={handleAccount} />
            <div className="page__content page__content--with-header">
                <div className="fieldset custom-form">
                    <h2 className="page__title" style={{textAlign: 'center'}}>Privacy Policy</h2>
                    <p style={{marginBottom: '50px'}}>
                        {/* <h4> Privacy Policy</h4> */}
                        <p> At GOLDTREEASTRO.COM, we understand that privacy is important to you. We have posted this privacy policy to let you know that we safeguard your personal information and do not share it with any unaffiliated third parties for marketing purposes.</p>

                        Effective on Jun 1, 2022
                        <p> We ask for and require certain personal and identification information in order to operate GOLDTREEASTRO.COM. We will never share, sell, disclose, or otherwise use your personally identifiable information other than to provide you the services you have requested. Any personal data that is acquired or stored by GOLDTREEASTRO.COM is for astrology consultation services only. By purchasing a service from our site, you grant us a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to edit, modify, adapt, reproduce, publish, translate, create derivative works from, distribute, and display such content, minus any personally identifiable information. Any personal data that is acquired or stored by GOLDTREEASTRO.COM is for astrology consultation services only.</p>

                        <p>We collect the following personal information from you such as Contact Information such as name, mailing address, phone number and Billing Information such as credit/debit card number and billing address for</p>

                        Fulfilling your order
                        Sending you an order confirmation
                        providing you requested service
                        Responding to customer service requests
                        Sending you a newsletter
                        Sending you marketing communications
                        Responding to your questions and concerns
                        Notification of Privacy Statement Changes
                        We may update this privacy statement to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
                    </p>
                </div>

            </div>

            <Loader fade={loader ? 'fadein' : 'fadeout'} />
        </div>
    )
}

export default Privacy
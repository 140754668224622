import React from 'react'
import {Link} from 'react-router-dom'

function Header(props) {
    return (
        <header className="header header--fixed">
            <div className="header__inner">
                <div className="header__icon header__icon--menu open-panel" onClick={props.onOpen}><span></span><span></span><span></span><span></span><span></span><span></span></div>
            <div className="header__logo header__logo--text">
            <Link to={''}>
               <img src={require('../assets/images/logo/logo.png')} style={{width:71}}  alt="test"/>
                </Link>
            </div>
            <div className="header__icon open-panel" data-panel="right" data-arrow="left">
            <div style={{width: 24}}></div>
            {/* <img src={require('../assets/images/icons/white/user.svg').default}  alt="test" onClick={props.onOpenAcc}/> */}
            </div>
        </div>
	</header>
  )
}

export default Header
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

function SideNav(props) {
  const [show, setShow] = useState(false)
  // const [title, setTitle] = useState('')
  const [type, setType] = useState('')
  const openModal = (type) => {
    setType(type)
    setShow(true)

  }
  const closeModal = () => {
    setShow(false)
  }
  return (<>
    <div className={props.onPass ? "body-overlay active" : "body-overlay"} style={props.onPass ? { display: 'block' } : { display: 'none' }}></div>
    <div id="panel-left">
      <div className={props.onPass ? "panel panel--left active" : "panel panel--left"}>
        {props.move === 'left' ?


          <div className="panel__navigation swiper-container-initialized swiper-container-horizontal">
            <div className="swiper-wrapper" style={{ display: 'flex', height: '100vh' }}>
              <div className="swiper-slide swiper-slide-active" style={{ width: '90%' }}>
                <div className="user-details">
                  <div className="user-details__thumb"><img src={require("../assets/images/photos/avatar.jpg")} alt="" title="" /></div>
                  <div className="user-details__title"><span>Welcome to</span>Astro Light</div>
                </div>
                <nav className="main-nav">
                  <ul>
                    <li><Link to={'/'}><img src={require("../assets/images/icons/gray/home.svg").default} alt="" title="" /><span>Home</span></Link></li>
                    <li><Link to={'#'} onClick={() => openModal('about')}><img src={require("../assets/images/icons/gray/user.svg").default} alt="" title="" /><span>About</span></Link></li>
                    <li><Link to={'#'} onClick={() => openModal('contact')}><img src={require("../assets/images/icons/gray/contact.svg").default} alt="" title="" /><span>Contact Us</span></Link></li>
                    <li><Link to={'/privacy'} onClick={() => openModal('privacy')}><img src={require("../assets/images/icons/gray/chat.svg").default} alt="" title="" /><span>Privacy Policy</span></Link></li>
                    <li><Link to={'#'} onClick={() => openModal('terms')}><img src={require("../assets/images/icons/gray/chat.svg").default} alt="" title="" /><span>Terms &amp; Conditions</span></Link></li>
                    <li><Link to={'#'} onClick={() => openModal('refund')}><img src={require("../assets/images/icons/gray/chat.svg").default} alt="" title="" /><span>Cancellation/Refund Policy</span></Link></li>
                    {/* <li><Link to={'/logout'} ><i style={{fontSize:22}} className="fa fa-sign-out" aria-hidden="true"></i><span>Logout</span></Link></li> */}

                  </ul>
                </nav>
              </div>
              <div className="swiper-slide swiper-slide-next" style={{ display: 'flex', width: '10%', justifyContent: 'center', paddingTop: '40px', alignItems: 'flex-start' }}>

                <img src={require("../assets/images/icons/black/close.svg").default} alt="" title="" style={{ width: 20 }} onClick={props.onOpen} />

              </div>
            </div></div>
          : ''}
      </div></div>
    <div id="panel-right">
      <div className={props.onRight ? "panel panel--right active" : "panel panel--right"}>
        {props.move === 'right' ?
          <div className="swiper-wrapper" style={{ display: 'flex', height: '100vh' }}>
            <div className="swiper-slide swiper-slide-next" style={{ display: 'flex', width: '10%', justifyContent: 'center', paddingTop: '40px', alignItems: 'flex-start' }}>

              <img src={require("../assets/images/icons/black/close.svg").default} alt="" title="" style={{ width: 20 }} onClick={props.onOpenAcc} />

            </div>
            <div className="swiper-slide swiper-slide-active" style={{ width: '90%' }}>
              <div className="user-profile">
                <div className="user-profile__thumb"><img src={require("../assets/images/photos/user.png")} alt="" title="" /></div>
                <div className="user-profile__name">{localStorage.getItem('phone').slice(2,12)}</div>
              </div>
              <nav className="main-nav">
                <ul>
                  {/* <li><Link to={'/'}><img src={require("../assets/images/icons/gray/user.svg")} alt="" title="" /><span>My Account</span></Link></li> */}
                  <li><Link to={'#'} onClick={() => openModal('about')}><img src={require("../assets/images/icons/gray/user.svg")} alt="" title="" /><span>About Us</span></Link></li>
                  <li><Link to={'#'} onClick={() => openModal('contact')}><img src={require("../assets/images/icons/gray/contact.svg")} alt="" title="" /><span>Contact Us</span></Link></li>
                  <li><Link to={'#'} onClick={() => openModal('privacy')}><img src={require("../assets/images/icons/gray/chat.svg")} alt="" title="" /><span>Privacy Policy</span></Link></li>
                  <li><Link to={'#'} onClick={() => openModal('terms')}><img src={require("../assets/images/icons/gray/chat.svg")} alt="" title="" /><span>Terms &amp; Conditions</span></Link></li>
                  <li><Link to={'#'} onClick={() => openModal('refund')}><img src={require("../assets/images/icons/gray/chat.svg")} alt="" title="" /><span>Cancellation / Refund Policy</span></Link></li>
                  {/* <li><Link to={'/logout'} ><i style={{fontSize:22}} className="fa fa-sign-out" aria-hidden="true"></i><span>Logout</span></Link></li> */}
                </ul>
              </nav></div>


          </div>


          : ''}</div></div>
    <Popup open={show} closeOnDocumentClick onClose={closeModal}>
      <div className="modal">
        <Link className="close" onClick={closeModal}>
          <img src={require("../assets/images/icons/black/close.svg").default} style={{ width: 10 }} alt='' />
        </Link>
        {type === 'about' ?
          <h2 className=" header popup__title"> AboutUs </h2>
          : type === 'contact' ?
          <h2 className=" header popup__title"> Contact </h2>
        :type === 'privacy'?
        <h2 className=" header popup__title"> Privacy Policy </h2>
      :type === 'terms'?
      <h2 className=" header popup__title"> Terms & Conditions </h2>
    :type === 'refund' ?<h2 className=" header popup__title">Cancellation / Refund Policy  </h2>
    :''}
        <div className="content">
          {type === 'about' ?
            <p>This app contains consultation of various activities like horoscope of an individual to have a better understanding on self and family's future, as we can check the combination for Education, Job, Business, Muhurtha time, Abroad travel, Government job, Loss/Gain in business, Change of job, Health, Property, Child birth, Divorce, Vehicle lucky number,. Relationship with family members in BrighunandiNadi system by just paying Rs. 528/- Only (Only in India).</p>
            : type === 'contact' ?
              <p>
                <div><strong>Address: </strong>No. 31/32, 5th Cross, 1st Main Near Lakshmi Layout, Abbigere, Chikkabanavara, Bangalore - 560090 </div>
                <div><strong>Phone: </strong>9844416555 </div>
                <div><strong>Email: </strong>contact@goldtreeastro.com</div>
              </p>
              : type === 'privacy' ?
                <p>
                  <h4> Privacy Policy</h4>
                  <p> At GOLDTREEASTRO.COM, we understand that privacy is important to you. We have posted this privacy policy to let you know that we safeguard your personal information and do not share it with any unaffiliated third parties for marketing purposes.</p>

                  Effective on Jun 1, 2022
                  <p> We ask for and require certain personal and identification information in order to operate GOLDTREEASTRO.COM. We will never share, sell, disclose, or otherwise use your personally identifiable information other than to provide you the services you have requested. Any personal data that is acquired or stored by GOLDTREEASTRO.COM is for astrology consultation services only. By purchasing a service from our site, you grant us a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to edit, modify, adapt, reproduce, publish, translate, create derivative works from, distribute, and display such content, minus any personally identifiable information. Any personal data that is acquired or stored by GOLDTREEASTRO.COM is for astrology consultation services only.</p>

                  <p>We collect the following personal information from you such as Contact Information such as name, mailing address, phone number and Billing Information such as credit/debit card number and billing address for</p>

                  Fulfilling your order
                  Sending you an order confirmation
                  providing you requested service
                  Responding to customer service requests
                  Sending you a newsletter
                  Sending you marketing communications
                  Responding to your questions and concerns
                  Notification of Privacy Statement Changes
                  We may update this privacy statement to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.


                </p>
                : type ==='terms' ? 
                <p>Effective Date: This Terms of Service Agreement was last updated on Jun 1, 2022. Please read this Agreement carefully. 

                By using GOLDTREEASTRO.COM, the “User” or “you” agree to these terms and conditions. 
                
                No persons are authorized to modify, copy, reproduce, republish, upload, post, transmit or distribute in any way any material from this site including code and software, without the consent of GOLDTREEASTRO.COM 
                
                All users and customers of GOLDTREEASTRO.COM, by the use of GOLDTREEASTRO.COM’s services, hereby agree to abide by, and acknowledge the following: Nature of Product and Terms of Usage 
                
                The Company offers a service that locates suitably qualified experts for the provision of independent astrology consultation services provided to the Customer as a unique reference that is designed to assist in the completion of the Customer’s enquiry. When you work with GOLDTREEASTRO.COM, you agree to the terms and conditions and authorize the company to charge your card for the agreed upon price. The transaction will appear on your statement under the name GOLDTREEASTRO. You are guaranteed to receive a quality service within an agreed upon time frame. 
                
                Phone Support 
                
                GOLDTREEASTRO.COM offers services via phone access. The call will be recorded so that we can meet your needs most effectively as a team. 
                
                Steps of Order 
                
                Placing an order: It is the responsibility of the customer to provide complete and accurate details when filling out the order form. At any time during the order process, the customer may need to be contacted. Providing inaccurate information is a violation of the terms and conditions and could result in a forfeit of the guarantee provided for the service. 
                
                Provided information: Because GOLDTREEASTRO.COM operates solely on customer-provided information; it is the customer’s responsibility to provide all the necessary data to help set the customer apart from the rest. 
                
                        
                Incorrect order placement: In the event that details indicated are inconsistent or don’t match the original order, GOLDTREEASTRO.COM has the right to not process the order. Failure to provide the right product, description and level could interfere with deadline requests and may result in additional charges. This process allows the company to ensure that each order is processed correctly and matched to the appropriate consultant. No additional charges will be made without contact to the customer.</p>
                :type ==='refund' ?
              <p>If you’re unsatisfied with your order, please contact a customer service representative who will assign you to a new consultant to better meet your needs. We do not offer cancellations or refunds unless otherwise agreed at our sole discretion. Refunds or reassignment will not be granted after 4 hours following the order completion date. Immediately after an order is placed, a professional consultant is assigned to the account. For this reason, we are unable to offer refunds for requests made 4 or more hours after an order is placed. However, customers requesting a refund within 4 hours of placing an order will receive a full refund.

              Notification of Refund/Satisfaction Guarantee Statement Changes
              
              We may update this Refund/Satisfaction Guarantee statement to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
              :''}
        </div>
      </div>
    </Popup>
  </>
  )
}

export default SideNav
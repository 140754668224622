import React from 'react'
import {Link} from 'react-router-dom'

function Footer(props) {
  return (
    <div id="bottom-toolbar" className="bottom-toolbar"><div className="bottom-navigation">
    <div className="swiper-container-toolbar swiper-toolbar swiper-container-initialized swiper-container-horizontal">
       <div className="bottom-navigation__pagination"></div>
      
       <div className="swiper-wrapper" >
         <div className="swiper-slide swiper-slide-active" >
           <ul className="bottom-navigation__icons">
           <li><Link to={'/'}><img src={require("../assets/images/icons/white/home.svg").default} alt="" title="" /></Link></li>
           <li><Link to={'#'} onClick={props.onOpenAcc}><img src={require("../assets/images/icons/white/user.svg").default} alt="" title="" /></Link></li>
           <li><Link to={'/apply'}><img src={require("../assets/images/icons/white/blocks.svg").default} alt="" title="" /></Link></li>
           <li><Link to={'#'}><img src={require("../assets/images/icons/white/cart.svg").default} alt="" title="" /></Link></li>
           <li><Link to={'/logout'}><i style={{fontSize:22}} className="fa fa-sign-out" aria-hidden="true"></i></Link></li>
           
           </ul>
         </div> 
         {/* <div className="swiper-slide swiper-slide-next" style="width: 1516px;">
           <ul className="bottom-navigation__icons">
           <li><a href="blog.html"><img src="assets/images/icons/white/news.svg" alt="" title=""></a></li>
           <li><a href="photos.html"><img src="assets/images/icons/white/photos.svg" alt="" title=""></a></li>
           <li><a href="videos.html"><img src="assets/images/icons/white/video.svg" alt="" title=""></a></li>
           <li><a href="chat.html"><img src="assets/images/icons/white/chat.svg" alt="" title=""></a></li>
           <li><a href="#" data-popup="social" className="open-popup"><img src="assets/images/icons/white/love.svg" alt="" title=""></a></li>
           </ul>
          </div> */}
       </div>
     {/* <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span> */}
     </div>
</div>	</div>
  )
}

export default Footer

import {
  BrowserRouter,
  Routes,
  Route, Navigate
} from "react-router-dom";
import Privacy from "./Pages/Privacy"
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />}/>
          <Route path="/privacy" element={<Privacy />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
